import * as React from "react";

import Layout from "../components/Layout";
import Card from "../components/Card";

const JournalPage = () => {
  return (
    <Layout seo={{ title: "Journal" }}>
      <div className="p-6 mx-auto">
        <Card />
      </div>
    </Layout>
  );
};

export default JournalPage;
