import { Link } from "gatsby";
import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";

export default function Card() {
  return (
    <Link
      to="/indielog-1"
      className="flex flex-col lg:flex-row items-center bg-white text-gray-900 py-4 rounded-md cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
    >
      {/* <div className="px-8 mb-4 w-full lg:w-auto lg:px-0 lg:mb-0 lg:-ml-12 flex justify-center items-center h-auto lg:h-40">
        <img
          className="max-h-full w-full lg:w-auto rounded-md shadow-md"
          alt="Wald"
          src="https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80"
        />
      </div> */}
      <div className="flex-1 px-8">
        <p className="text-xs text-gray-500">18/01/2021, 21:25</p>
        <h3 className="text-lg font-bold mb-2">
          Indielog #1 - Follow my journey building a SaaS app from scratch
        </h3>
        <p>
          Each week, I will write about my ideas, progress and learnings. I will
          be completely transparent and I hope that this will become an
          interesting case study over time for anyone who is looking to build a
          SaaS business from scratch.{" "}
        </p>
      </div>
      <div className="p-4">
        <AiOutlineArrowRight className="text-3xl" />
      </div>
    </Link>
  );
}
